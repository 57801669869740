<template>
  <div class="only-img">
    <template v-if="[4].includes(type)">
      <YField
        type="textarea"
        :maxlength="200"
        show-word-limit
        :name="`selecters[${index}].content`"
        label="题目描述"
        :field-status="disabled ? 'disabled' : 'edit'"
        :rules="[...(disabled ? [] : ['required', 'whiteSpace'])]"
      />
    </template>
    <transition name="el-zoom-in-top">
      <div>
        <template v-if="[1, 2].includes(type)">
          <div class="head">
            <div class="field-name">
              {{ `选项${index + 1}` }}
            </div>
            <el-dropdown @command="handleCommand($event)">
              <span class="menu">
                <span class="dot" />
                <span class="dot" />
                <span class="dot" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="delete">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <YField
            :name="`selecters[${index}].title`"
            label="选项内容"
            type="textarea"
            :maxlength="200"
            show-word-limit
            :rules="['required', 'whiteSpace']"
          />
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'QuestionaireField',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    disabled({ type }) {
      return type === 3
    },
  },
  methods: {
    handleCommand(event) {
      this.$emit(event, this.index)
    },
  },
}
</script>

<style lang="scss" scoped>
.only-img {
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    .yfield {
      margin-bottom: 0;
    }
    .text {
      display: flex;
      align-items: center;
      .border {
        border: 1px solid #f0f0f0;
        border-radius: 50%;
      }
    }
    span {
      color: #262626;
      font-size: 14px;
    }

    svg {
      display: inline-block;
      margin-right: 5px;
      font-size: 24px;
    }
    > div {
      display: flex;
      align-items: center;
    }
  }
  .no-margin {
    margin-bottom: 0;
  }
  .inline-block {
    display: inline-block;
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    .dot {
      display: inline-block;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: #000;
    }
  }
  .tips {
    margin-bottom: 8px;
    padding-left: 160px;
    color: #8c8c8c;
    font-size: 12px;
  }
  .uploadVideo {
    margin-left: 10px;
  }
  .el-input-number.is-without-controls .el-input__inner {
    width: 120px !important;
    text-align: left;
  }
}
</style>
