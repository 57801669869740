<template>
  <div class="smart-room-time-task container">
    <YForm v-model="tableForm" inline class="y-form" size="medium">
      <headerBar />
      <YField
        size="medium"
        prefix-icon="el-icon-search"
        name="title"
        placeholder="请输入问卷标题"
      ></YField>
      <YField
        name="state"
        component="el-select"
        placeholder="问卷状态"
        :data-source="QUESTIONAIRE_STATUS"
      />
      <YButton do="search" class="btn btn_blue"></YButton>
      <YButton class="btn btn_blue" @click="add">
        <i class="el-icon-plus el-icon--left" />
        新建问卷
      </YButton>

      <div class="exam-operate">
        <el-link
          type="info"
          underline
          @click="deleteQuestionaire()"
          class="mr8"
        >
          <i class="el-icon-delete"></i>
          删除问卷
        </el-link>
      </div>

      <YQueryTable
        class="table"
        tooltip-effect="dark"
        ref="queryTable"
        :serve="getList"
        @selection-change="handleSelectionChange"
      >
        <template>
          <el-table-column type="selection" />
          <el-table-column
            label="标题"
            min-width="120"
            prop="title"
            show-overflow-tooltip
          />
          <el-table-column
            label="发布人"
            min-width="120"
            prop="username"
            show-overflow-tooltip
          />
          <el-table-column
            label="问卷状态"
            min-width="120"
            prop="content"
            show-overflow-tooltip
          >
            <template slot-scope="props">
              <div>
                {{ QUESTIONAIRE_STATUS.get(props.row && props.row.state) }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="绑定状态" min-width="120" show-overflow-tooltip>
            <template slot-scope="props">
              <div>
                {{ props.row.courseId ? '已绑定' : '未绑定' }}
              </div>
            </template>
          </el-table-column> -->
          <el-table-column
            label="更新时间"
            prop="updateTime"
            min-width="120"
            show-overflow-tooltip
          />
          <el-table-column label="操作" min-width="420" show-overflow-tooltip>
            <template slot-scope="props">
              <el-link
                type="primary"
                class="mr8"
                :disabled="[1, 2, 3].includes(props.row.state)"
                @click="edit(props.row)"
              >
                编辑
              </el-link>
              <el-link
                type="primary"
                class="mr8"
                :disabled="[1, 2, 3].includes(props.row.state)"
                @click="editContent(props.row)"
              >
                编辑内容
              </el-link>
              <el-link
                type="primary"
                class="mr8"
                @click="editContent(props.row, true)"
                >预览</el-link
              >
              <!-- <el-link type="primary" class="mr8" :disabled="props.row.state === 0" @click="setVideo(props.row)">
                问卷绑定
              </el-link> -->
              <el-link
                type="primary"
                class="mr8"
                :disabled="[1, 2, 3].includes(props.row.state)"
                @click="publish(props.row)"
              >
                发布问卷
              </el-link>
              <el-link
                type="primary"
                class="mr8"
                :disabled="[1, 0, 3].includes(props.row.state)"
                @click="stop(props.row)"
              >
                停止收集
              </el-link>
              <el-link type="primary" class="mr8" @click="statistic(props.row)"
                >统计</el-link
              >
              <el-link
                :disabled="[2].includes(props.row.state)"
                type="danger"
                @click="deleteQuestionaire([props.row.surveyId])"
              >
                删除
              </el-link>
            </template>
          </el-table-column>
        </template>
      </YQueryTable>
    </YForm>

    <el-dialog
      class="dialog_wrapper"
      :title="isEdit ? '编辑问卷' : '新建问卷'"
      :visible.sync="dialogShow"
      width="450px"
    >
      <YForm
        v-model="formValues"
        v-loading="formLoading"
        label-position="top"
        @submit="saveForm"
      >
        <YField
          label="问卷标题"
          name="title"
          :rules="['required', 'whiteSpace']"
          :maxlength="15"
          show-word-limit
        />
        <YField
          label="备注"
          name="remark"
          type="textarea"
          :maxlength="200"
          show-word-limit
        />
        <div class="dialog-footer">
          <YButton class="btn btn_blue" do="submit" />
          <YButton class="btn btn_cancle btn_normal" @click="dialogShow = false"
            >取消</YButton
          >
        </div>
      </YForm>
    </el-dialog>

    <el-dialog
      width="450px"
      class="dialog_wrapper"
      :title="isEdit ? '编辑问题' : '新建问题'"
      :visible.sync="questionDialog"
    >
      <YForm
        v-model="questionForm"
        label-position="top"
        @submit="saveQuestionaire"
      >
        <div class="scroll-form">
          <YField
            label="题目内容"
            :maxlength="15"
            show-word-limit
            name="content"
            :rules="['required', 'whiteSpace']"
          />
          <YField
            label="是否必填"
            name="required"
            component="el-radio-group"
            :rules="['required']"
          >
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </YField>
          <YField
            label="题目类型"
            :field-status="isEdit ? 'disabled' : 'edit'"
            inline
            component="el-select"
            name="questionType"
            :rules="['required']"
            filterable
            :data-source="QUESTONAIRE_QUESTION_TYPE"
            @change="changeType"
          />

          <YButton
            v-if="[1, 2].includes(questionForm.questionType)"
            @click="addRadio"
            >添加选项</YButton
          >
          <component
            :is="QuestionaireField"
            v-for="(item, i) in questionForm.selecters"
            :key="`selecter${i}`"
            :index="i"
            :type="questionForm.questionType"
            @delete="deleteField"
          />
        </div>

        <div class="dialog-footer">
          <YButton class="btn btn_blue" do="submit" />
          <YButton
            class="btn btn_cancle btn_normal"
            type="default"
            @click="questionDialog = false"
            >取消</YButton
          >
        </div>
      </YForm>
    </el-dialog>

    <el-dialog
      class="dialog_wrapper"
      title="课程绑定"
      :visible.sync="videoDialog"
      width="450px"
    >
      <YForm
        v-model="videoForm"
        v-loading="formLoading"
        label-width="120px"
        label-position="top"
        @submit="saveVideo"
      >
        <YField
          label="课程"
          component="el-select"
          name="courseId"
          :rules="['required']"
          filterable
          :data-source="videoMap"
        />
        <div class="dialog-footer">
          <YButton class="btn btn_blue" do="submit" />
          <YButton
            class="btn btn_cancle btn_normal"
            @click="videoDialog = false"
            >取消</YButton
          >
        </div>
      </YForm>
    </el-dialog>

    <el-drawer :title="drawerTitle" size="480px" :visible.sync="drawerDisplay">
      <div class="drawer-contaier">
        <div v-show="questioniareContent">{{ questioniareContent }}</div>
        <div class="question-main">
          <el-button v-show="!isPreview" size="small" @click="addQuestionaire"
            >添加问题</el-button
          >
          <div class="question-list">
            <div v-for="(item, i) in questionsList" :key="item.questionId">
              <div class="head">
                <div :class="['field-name', item.required ? 'required' : '']">
                  <!-- <div v-if="item.contentArr"> -->
                  <!-- <span v-for="(str, key) in item.contentArr" :key="'content' + key" :class="{ red: key === 1 }">
                      {{ str }}
                    </span>
                    <span>（类型：{{ QUESTONAIRE_QUESTION_TYPE.get(item.questionType) }}）</span>
                  </div> -->
                  <div>
                    {{ item.content }}（类型：{{
                      QUESTONAIRE_QUESTION_TYPE.get(item.questionType)
                    }}）
                  </div>
                </div>
                <el-dropdown
                  v-show="!isPreview"
                  trigger="click"
                  @command="handleCommand"
                >
                  <span class="menu">
                    <span class="dot" />
                    <span class="dot" />
                    <span class="dot" />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :index="i"
                      :question="item"
                      command="delete"
                      >删除</el-dropdown-item
                    >
                    <el-dropdown-item
                      :index="i"
                      :question="item"
                      command="insert"
                      >插入</el-dropdown-item
                    >
                    <el-dropdown-item :index="i" :question="item" command="edit"
                      >编辑</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <el-input
                v-if="[4].includes(item.questionType)"
                disabled
                type="textarea"
                :placeholder="item.selecters[0].content"
              />
              <el-radio-group v-if="[1].includes(item.questionType)" disabled>
                <el-radio
                  v-for="radio in item.selecters"
                  :label="radio.selecterId"
                  :key="radio.selecterId"
                  class="block"
                >
                  {{ `${radio.content}` }}
                </el-radio>
              </el-radio-group>
              <el-checkbox-group
                v-if="[2].includes(item.questionType)"
                disabled
              >
                >
                <el-checkbox
                  v-for="item in item.selecters"
                  :key="item.selecterId"
                >
                  {{ `${item.content}` }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
const SELECT_COOUNT_MIN = 2; // 选项最小数量
const SELECT_COOUNT_MAX = 5; // 选项最大数量

import {
  fetchQuestionaireList, // 问卷列表
  deleteQuestionaire, // 删除问卷
  addQuestionaire, // 新建问卷
  updateQuestionaire, // 编辑问卷
  fetchQuestionaireInfo, // 问卷详情
  questionairePublic, // 问卷发布
  fetchQuestionaireListById, // 通过id查询问卷列表
  deleteQuestionaireField, // 删除问卷表单
  addQuestionaireField, // 新建表单
  updateQuestionaireField,
  updateCourse, // 课程更新
  fetchAllCourse, // 获取所有课程
} from "@/apis/backStageEndAPI/examStageManagement/index.js";
import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";
import {
  QUESTONAIRE_QUESTION_TYPE,
  QUESTIONAIRE_STATUS,
  QUESTIONAIRE_TYPE,
} from "@/utils/enum";
import QuestionaireField from "./components/questionaireField.vue";

export default {
  name: "Questionaire",
  components: {
    headerBar,
  },
  data() {
    return {
      QUESTONAIRE_QUESTION_TYPE,
      QUESTIONAIRE_STATUS,
      QUESTIONAIRE_TYPE,
      QuestionaireField,
      isPreview: false,
      questionForm: {
        selecters: [{}],
      },
      tableForm: {},
      bindForm: {},
      drawerDisplay: false,
      drawerTitle: "",
      selectedRow: [],
      dialogShow: false,
      questionDialog: false,
      describe: "",
      isEdit: false,
      videoForm: {},
      formValues: {},
      videoDialog: false,
      questionsList: [],
      videoMap: null,
      formLoading: true,
      surveyId: null,
      isInsert: false,
      index: 0,
      tableList: [],
      questioniareContent: "",
      currentRow: {},
    };
  },
  methods: {
    handleSelectionChange(row) {
      this.selectedRow = row;
    },
    statistic(row) {
      this.$router.push(`/questionaire/statistic?id=${row.surveyId}`);
    },
    async saveQuestionaire() {
      const { questionType, selecters = [] } = this.questionForm;
      if ([1, 2].includes(questionType)) {
        const len = selecters.length;
        if (len < SELECT_COOUNT_MIN) {
          return this.$message.warning(`选项不能少于${SELECT_COOUNT_MIN}个`);
        }
        if (len > SELECT_COOUNT_MAX) {
          return this.$message.warning(`选项不能多于${SELECT_COOUNT_MAX}个`);
        }
        const formateArr = selecters.map((item, index) => {
          const { title = "", square = "" } = item;
          return {
            ...item,
            content: title,
            selecterSeq: index++,
            title,
            square,
          };
        });
        this.questionForm.selecters = formateArr;
      } else {
        this.questionForm.selecters.forEach((item, index) => {
          item.selecterSeq = index++;
        });
      }
      const index = this.index;
      this.questionForm = {
        ...this.questionForm,
        surveyId: this.surveyId,
        questionSeq: index,
      };
      const request = this.isEdit
        ? updateQuestionaireField
        : addQuestionaireField;
      await request(this.questionForm);
      this.questionDialog = false;
      const spliceLen = this.isEdit ? 1 : 0;
      // gaoling修改保存完一个新建问题以后调取问题列表接口
      this.isInsert
        ? this.questionsList.splice(index, spliceLen, this.questionForm)
        : this.editContent(this.currentRow);
      // : this.questionsList.push(this.questionForm);
    },
    addRadio() {
      const { questionType, selecters = [] } = this.questionForm;
      if ([1, 2].includes(questionType)) {
        const len = selecters.length;
        if (len >= SELECT_COOUNT_MAX) {
          return this.$message.warning(`选项不能多于${SELECT_COOUNT_MAX}个`);
        }
      }
      this.questionForm.selecters.push({});
    },
    changeType() {
      this.questionForm.selecters = [{}];
    },
    async stop(row) {
      await this.$confirm("确定要停止问卷收集吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      await updateQuestionaire({ state: 1, surveyId: row.surveyId });
      this.$refs.queryTable.runServe();
    },
    async saveVideo() {
      try {
        await updateCourse({
          ...this.videoForm,
        });
        this.$refs.queryTable.runServe();
      } finally {
        this.videoDialog = false;
      }
    },
    deleteField(index) {
      if (this.questionForm.selecters.length <= 1) return;
      this.questionForm.selecters.splice(index, 1);
    },
    async insertCommand(question, index) {
      this.isInsert = true;
      this.index = ++index;
      this.isEdit = false;
      this.questionDialog = true;
      this.questionForm = {};
    },
    async deleteCommand(question, index) {
      const { questionSeq, questionId } = question;
      const params = { questionSeq, questionId, surveyId: this.surveyId };
      await deleteQuestionaireField(params);
      this.questionsList.splice(index, 1);
    },
    async editCommand(question, index) {
      const { questionType } = question;
      if ([1, 2].includes(questionType)) {
        question.selecters = question.selecters.map((item) => {
          item.square = item.remark;
          item.title = item.content;
          return item;
        });
      }
      this.isInsert = true;
      this.index = index;
      this.isEdit = true;
      this.questionForm = question;
      this.questionDialog = true;
    },
    handleCommand(...args) {
      const [commnad, component] = args;
      const { question, index } = component.$attrs;
      this[`${commnad}Command`](question, index);
    },
    async editContent(row, isPreview) {
      this.currentRow = row;
      this.isPreview = isPreview;
      this.drawerTitle = row.title;
      this.drawerDisplay = true;
      this.surveyId = row.surveyId;
      this.questioniareContent = row.remark;
      const { questions = [] } = await fetchQuestionaireListById({
        surveyId: row.surveyId,
      });
      this.questionsList = questions;
      this.index = this.questionsList.length;
    },
    addQuestionaire() {
      this.isEdit = false;
      this.isInsert = false;
      this.index++;
      this.questionDialog = true;
      this.questionForm = {};
    },
    async publish(row) {
      await this.$confirm("确定要发布问卷吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      const { type, surveyId } = row;
      // await queryQuestionairePublic({ type, surveyId })
      const reqParams = { type, surveyId, state: 2 };
      await questionairePublic(reqParams);
      this.$refs.queryTable.runServe();
    },
    async setVideo(row) {
      this.formLoading = true;
      this.videoDialog = true;
      if (!this.videoMap) {
        this.fetchAllCourse();
      }
      this.videoForm = {
        examId: row.surveyId,
      };
      this.formLoading = false;
    },
    async saveForm() {
      const request = this.isEdit ? updateQuestionaire : addQuestionaire;
      try {
        await request({
          ...this.formValues,
          type: this.formValues.type || "1",
        });
        this.$refs.queryTable.runServe({ currentPage: 1 });
      } finally {
        this.dialogShow = false;
      }
    },
    async fetchAllCourse() {
      const res = await fetchAllCourse();
      this.videoMap = res.map((item) => {
        return { value: item.courseId, label: item.courseTitle };
      });
    },
    add() {
      this.isEdit = false;
      this.formValues = {};
      this.dialogShow = true;
      this.formLoading = false;
    },
    async edit(row) {
      this.isEdit = true;
      this.dialogShow = true;
      this.formLoading = true;
      const { rows = {} } = await fetchQuestionaireInfo(row.surveyId);
      this.formValues = {
        ...rows,
      };
      this.formLoading = false;
    },
    async deleteQuestionaire(ids) {
      const id = ids || this.selectedRow.map((item) => item.surveyId);
      if (!id.length) {
        return this.$message.warning("至少选择一条数据");
      }
      const hasExaming = id.some((e) => {
        return this.tableList.find((item) => item.surveyId === e).state === 2;
      });
      if (hasExaming) {
        return this.$message.warning("收集中的问卷不能删除");
      }
      await this.$confirm("确实要删除吗？");
      await deleteQuestionaire(id);
      this.$refs.queryTable.runServe({ currentPage: 1 });
    },
    async getList({ params, formValues }) {
      const { records = [], total = 0 } = await fetchQuestionaireList({
        pageSize: params.pageSize,
        pageNumber: params.currentPage,
        sortOrder: "asc",
        ...formValues,
      });
      this.tableList = records;
      return {
        total,
        data: records,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.exam- {
  &operate {
    margin-bottom: 12px;
    text-align: right;
    .el-icon-delete {
      color: #f65160;
    }
  }
}
.operate {
  margin-bottom: 12px;
  text-align: right;
  .el-icon-delete {
    color: #f65160;
  }
}
.drawer-container {
  padding: 0 16px;
}

.scroll-form {
  overflow-y: scroll;
  margin: 0 -20px 20px;
  padding: 0 20px;
  max-height: 450px;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  .dot {
    display: inline-block;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #000;
  }
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 63px;
  .yfield {
    margin-bottom: 0;
  }
  .text {
    display: flex;
    align-items: center;
    .border {
      border: 1px solid #f0f0f0;
      border-radius: 50%;
    }
  }
  span {
    color: #262626;
    font-size: 14px;
  }

  svg {
    display: inline-block;
    margin-right: 5px;
    font-size: 24px;
  }
  > div {
    display: flex;
    align-items: center;
  }
}
.block {
  margin-bottom: 12px;
}
.smart-room-time-task {
  .red {
    color: red;
  }
}
::v-deep {
  .el-drawer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .drawer-contaier {
    overflow: auto;
    flex: 1;
    padding: 24px;
  }
  .el-drawer__header {
    flex: 0 0 60px;
    margin: 0;
    padding: 0 24px;
    outline: none;
    border: none;
    border-bottom: 1px solid #efefef;
    line-height: 60px;
  }
  .el-drawer__body {
    display: flex;
    overflow: hidden;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }
}
.field-name {
  &.required {
    &::before {
      margin-right: 4px;
      color: #f56c6c;
      content: "*";
    }
  }
}
</style>
